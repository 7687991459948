import { supabase } from '../useSupabase.js'
import useAuthUser from './UseAuthUser'
import { v4 as uuidv4 } from 'uuid'

export default function useApi () {
  const { user } = useAuthUser()

  const list = async (table) => {
    const { data, error } = await supabase
      .from(table)
      .select('*')
    if (error) throw error
    return data
  }

  const fetchCount = async (table) => {
    const { data, count, error } = await supabase
      .from(table)
      .select('*', { count: 'exact' })
    if (error) throw error
    return { data, count }
  }

  const getById = async (table, id) => {
    const { data, error } = await supabase
      .from(table)
      .select('*')
      .eq('id', id)
    if (error) throw error
    return data[0]
  }

  const post = async (table, form) => {
    const { data, error } = await supabase
      .from(table)
      .insert([{
        ...form,
        user_id: user.value.id
      }])
    if (error) throw error
    return data
  }

  const update = async (table, form) => {
    const { data, error } = await supabase
      .from(table)
      .update({
        ...form
      })
      .match({ id: form.id })
    if (error) throw error
    return data
  }

  const remove = async (table, id) => {
    const { data, error } = await supabase
      .from(table)
      .delete()
      .match({ id })
    if (error) throw error
    return data
  }

  const uploadImg = async (file, storage) => {
    const fileName = uuidv4()

    const { error } = await supabase
      .storage
      .from(storage)
      .upload(fileName, file, {
        cacheControl: '3600',
        upsert: false
      })
    const publicUrl = await getUrlPublic(fileName, storage)
    if (error) throw error
    return publicUrl
  }

  const getUrlPublic = async (fileName, storage) => {
    const { publicURL, error } = await supabase
      .storage
      .from(storage)
      .getPublicUrl(fileName)
    if (error) throw error
    return publicURL
  }

  return {
    list,
    fetchCount,
    getById,
    post,
    update,
    remove,
    uploadImg
  }
}

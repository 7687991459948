<template>
  <q-page padding>
    <h5>Temas</h5>
    <q-form
      @submit.prevent="handleSubmit"
      class=" q-gutter-md"
    >
      <q-input
        label="Nome tema"
        v-model="form.name"
        type="text"
        :rules="[ val => val && val.length > 0 || 'Favor informe um nome valido!']"
      />
      <q-input
        label="Descrição tema"
        v-model="form.description"
        type="textarea"
        :rules="[ val => val && val.length > 0 || 'Favor informe uma descrição valida!']"
      />
      <q-btn
        :label=" isUpdated? 'Atualizar': 'Salvar'"
        color="positive"
        type="submit"
      />
      <q-btn
        label="Cancelar"
        color="warning"
        :to="{name:'theme'}"
      />

    </q-form>
  </q-page>
</template>
<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import useApi from '../../composables/UseApi'
import useNotify from '../../composables/UseNotify'
import { useRouter, useRoute } from 'vue-router'
import { useQuasar } from 'quasar'

export default defineComponent({
  name: 'PageFormTema',

  setup () {
    const router = useRouter()
    const route = useRoute()
    const $q = useQuasar()
    const table = 'Tema'
    const { post, getById, update } = useApi()
    const { notifyError, notifySuccess } = useNotify()
    const isUpdated = computed(() => route.params.id)
    onMounted(async () => {
      if (isUpdated.value) {
        await handleGetTheme(isUpdated.value)
      }
    })

    let theme = {}

    const form = ref({
      name: '',
      description: ''
    })

    const handleSubmit = async () => {
      try {
        if (isUpdated.value) {
          await update(table, form.value)
          notifySuccess('Tema atualizada com sucesso!')
        } else {
          await post(table, form.value)
          notifySuccess('Tema salva com sucesso!')
        }
        router.push({ name: 'theme' })
      } catch (error) {
        notifyError(error.message)
      }
    }

    const handleGetTheme = async (id) => {
      try {
        $q.loading.show()
        theme = await getById(table, route.params.id)
        form.value = theme
        $q.loading.hide()
      } catch (error) {
        notifyError(error.message)
      }
    }

    return { form, handleSubmit, isUpdated }
  }
})
</script>
